import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./plugins/router";
import vuetify from "./plugins/vuetify";
import { name, version } from "@/../package.json";
import "./assets/css/global.css";
import "./assets/tailwind.css";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import "./filters";
import VueHorizontalTimeline from "vue-horizontal-timeline";
import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";
import DefaultLayout from "@/layouts/Default";
import BlankLayout from "@/layouts/BlankLayout";
// Service Worker
import "./registerServiceWorker";
// Plugins
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import VueMask from "v-mask";
import infiniteScroll from "./plugins/infinite-scroll";
import { VueMaskDirective } from "v-mask";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor /* { default global options } */);
Vue.directive("mask", VueMaskDirective);
Vue.directive("infinite-scroll", infiniteScroll);
Vue.use(VueMask);
Vue.component("DefaultLayout", DefaultLayout);
Vue.component("BlankLayout", BlankLayout);
Vue.config.productionTip = false;

if (
  process.env.NODE_ENV === "production" &&
  window.location.hostname !== "localhost"
) {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: "https://8c3eea5786a0494db6f4797da8914716@o92838.ingest.sentry.io/4504016364109824",
    environment:
      window.location.host.indexOf(".melembre.com.br") !== -1
        ? "production"
        : "staging",
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    release: name + "@" + version,
    sendDefaultPii: true,
  });
}

const moment = require("moment");

require("moment/locale/pt-br");
moment.locale("pt-BR");
Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(VueHorizontalTimeline);

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

Vue.component("v-chart", ECharts);
Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs",
  },
});

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

console.debug("Aplicação criada");
