import http from "@/plugins/axios";
import store from "@/store";

const VERSION = "/v1";

export default {
  search(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/contrato", {
      params: { ...params },
    });
  },

  get(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/contrato/" + id);
  },

  pegarHistorico(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(
      `${empresa.url_instancia}${VERSION}/contrato/${id}/historico`
    );
  },

  importar(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(
      empresa.url_instancia + VERSION + "/contrato/importar",
      payload
    );
  },

  statusImportacao(idImportacao) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(
      empresa.url_instancia + VERSION + `/importacao_contrato/${idImportacao}`
    );
  },
};
