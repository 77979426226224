import http from "@/plugins/axios";

export default class BaseService {
  constructor(version, resource, uniqueKey = "id") {
    this.version = version;
    this.resource = resource;
    this.httpClient = http.api;
    this.uniqueKey = uniqueKey;
  }
  async get(params) {
    return this.httpClient.get(`${this.version}/${this.resource}`, { params });
  }
  async getSingle(id, params) {
    return this.httpClient.get(`${this.version}/${this.resource}${id}`, {
      params,
    });
  }
  async patch(payload) {
    const resourceId = payload[this.uniqueKey];
    delete payload.id;
    return this.httpClient.patch(
      `${this.version}/${this.resource}/${resourceId}`,
      payload
    );
  }
  async post(payload) {
    return this.httpClient.post(`${this.version}/${this.resource}`, payload);
  }
  async delete(id) {
    return this.httpClient.delete(`${this.version}/${this.resource}/${id}`);
  }
}
