import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  get(id) {
    return http.api.get(`/v1/evento/${id}`);
  },

  listar(params) {
    return http.api.get("/v1/evento", { params });
  },

  salvar(params) {
    if (params.id) {
      let id = params.id;
      delete params.id;
      delete params.criado_em;
      return http.api.patch(VERSION + `/evento/${id}`, params);
    } else {
      return http.api.post(VERSION + `/evento`, params);
    }
  },

  listarAnexosEvento(params) {
    return http.api.get("/v1/anexo_email", { params });
  },

  salvarAnexoEvento(payload) {
    return http.api.post("/v1/anexo_email", payload);
  },

  removeAnexoEvento(anexoId) {
    return http.api.delete(`/v1/anexo_email/${anexoId}`);
  },

  testarEvento(payload) {
    return http.api.post(`/v1/evento/teste`, payload);
  }
};
