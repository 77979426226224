import { FluxosService } from "@/services/fluxosAutomaticos";
const state = () => {
  return {
    fluxosAutomaticos: null,
  };
};

const mutations = {
  setFLuxosAutomaticos(state, payload) {
    state.fluxosAutomaticos = payload;
  },
};

const actions = {
  async getFluxos({ commit, rootState }, payload) {
    try {
      const { id: empresaId } = rootState.empresa?.empresaSelecionada;
      const { data } = await FluxosService.get({
        empresaId: empresaId,
        params: {},
      });
      commit("setFLuxosAutomaticos", data);
      return data;
    } catch (e) {
      Promise.reject(e);
    }
  },
  async alteraFluxo({ commit, rootState }, { payload, fluxoId }) {
    try {
      const { id: empresaId } = rootState.empresa?.empresaSelecionada;
      const { data } = await FluxosService.patch({
        empresaId: empresaId,
        params: payload,
        fluxoId: fluxoId,
      });
      return data;
    } catch (e) {
      Promise.reject(e);
    }
  },
  async uploadArquivo({ commit, rootState }, payload) {
    try {
      const { data } = await FluxosService.uploadArquivo(payload);
      return data;
    } catch (e) {
      Promise.reject(e);
    }
  },
};

const getters = {
  fluxosAutomaticos: (state) => state.fluxosAutomaticos,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
