import BaseService from "./base";
const faqService = () => {
  const FaqService = new BaseService("v1", "faq");
  FaqService.ordenar = (payload) => {
    return FaqService.httpClient.post(
      `${FaqService.version}/${FaqService.resource}/ordenar`,
      payload
    );
  };
  return FaqService;
};
const FaqService = faqService();
export default FaqService;
