export default [
  {
    path: "/pesquisas",
    name: "pesquisas",
    component: () =>
      import(/* webpackChunkName: "pesquisa" */ "./pages/index.vue"),
    meta: {
      title: "Pesquisas",
      precisaAutenticacao: true,
      precisaEmpresa: true
    },
  },
  {
    path: "/pesquisas/:id",
    name: "pesquisa",
    component: () =>
      import(/* webpackChunkName: "pesquisa" */ "./pages/crudPesquisa.vue"),
    meta: {
      title: "Pesquisa",
      precisaAutenticacao: true,
      precisaEmpresa: true
    },
  },
];
