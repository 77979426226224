<template>
  <v-app>
    <v-system-bar
      app
      dark
      window
      color="red"
      height="40"
      class="justify-center font-weight-medium"
      v-if="$store.state.enviroment.name == 'staging'"
    >
      HOMOLOGAÇÃO
    </v-system-bar>

    <Menu ref="menu" v-if="usuario && usuario.id"></Menu>

    <v-main
      fill-height
      class="main-default"
      :class="{ 'main-staging': $store.state.enviroment.name == 'staging' }"
    >
      <v-container
        v-if="(usuario && usuario.id) || $route.name == 'Erro'"
        fluid
        class="pa-0"
      >
        <slot></slot>
      </v-container>

      <v-container v-else fill-height fluid class="ml-loading">
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <img src="@/assets/img/icone.png" class="logo" title="Logo" />

            <v-progress-circular
              indeterminate
              color="grey"
              class="d-flex mx-auto"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/comum/Menu";

export default {
  name: "DefaultLayout",
  components: {
    Menu,
  },
  mounted() {
    const targetNode = document.querySelector(".v-main__wrap");
    const config = { childList: true, subtree: true };

    const callback = function () {
      const header = document.querySelector(".v-app-bar");
      if (header) {
        targetNode.classList.remove("headerless");
      } else {
        targetNode.classList.add("headerless");
      }
      if (targetNode.clientHeight < targetNode.scrollHeight) {
        targetNode.classList.add("scroll-visible");
      } else {
        targetNode.classList.remove("scroll-visible");
      }
    };
    const observer = new MutationObserver(callback);

    observer.observe(targetNode, config);
  },
  methods: {
    toggleDrawerMini() {
      this.$refs.menu.toggleDrawerMini();
    },
  },
  computed: {
    ...mapGetters("auth", {
      usuario: "getUsuario",
    }),
  },
};
</script>

<style lang="scss">
.v-main__wrap {
  &::-webkit-scrollbar {
    width: 15px; /* Set the width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Color of the scrollbar thumb */
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
    border: 2px solid #f1f1f1; /* Optional border around the track */
  }
}
.v-application.theme--light {
  .v-main.main-default .v-main__wrap {
    background-color: #f9fbfc !important;
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: auto;
    &.headerless {
      height: 100vh;
    }
  }
}
.v-application.theme--light {
  .v-main.main-staging.main-default .v-main__wrap {
    background-color: #f9fbfc !important;
    height: calc(100vh - 104px);
    overflow-y: auto;
    overflow-x: auto;
    &.headerless {
      height: calc(100vh - 40px);
    }
  }
}

.logo {
  height: 80px;
  margin-bottom: 16px;
  width: 80px;
}
.ml-loading {
  height: 100dvh;
}
</style>
