import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import enviroment from "@/assets/js/enviroment";
import { version } from "@/../package.json";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,

  state: {
    app: {
      /** A versão do app. */
      version: version,
    },

    /** Empresa selecionada. */
    empresa: null,

    /** Ambiente da aplicação. */
    enviroment: enviroment,
  },
  mutations: {
    toggleDrawer(state) {
      state.app.drawer = !state.app.drawer;
    },
  },
});
