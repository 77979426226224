import http from "@/plugins/axios";

export default {
  //requisições da fatura

  buscar(id) {
    return http.api.get(`/v1/fatura/${id}`);
  },

  getFatura(params) {
    return http.api.get("/v1/fatura", { params });
  },
  getFaturaAtual(params) {
    return http.api.get("/v1/fatura/atual", { params });
  },
  getItemsFatura(params) {
    return http.api.get("/v1/item_fatura", { params });
  },
  enviarFatura(id_fatura) {
    return http.api.post(`/v1/fatura/${id_fatura}/enviar`);
  },
  editaFatura({ id, ...payload }) {
    return http.api.patch(`/v1/fatura/${id}`, payload);
  },
  aprovarFatura(faturaId) {
    return http.api.post(`/v1/fatura/${faturaId}/enviar`);
  },
  descartarFatura(faturaId) {
    return http.api.post(`/v1/fatura/${faturaId.id}/descartar`);
  },
  getPrecificacao(params) {
    return http.api.get(`/v1/fatura/precificacao`, { params });
  },
  editarPrecificacao(payload, empresaId) {
    const precificacoes = JSON.parse(JSON.stringify(payload));
    precificacoes.forEach((item) => delete item.editando);
    return http.api.patch(`/v1/precificacao`, precificacoes, {
      params: { empresa_id: empresaId },
    });
  },

  //requisições para item fatura

  criaOuEditaItemFatura(payload) {
    if (payload.id) {
      let itemId = payload.id;
      delete payload.id;
      return http.api.patch(`/v1/item_fatura/${itemId}`, payload);
    } else {
      return http.api.post("/v1/item_fatura", payload);
    }
  },
  deletaItemFatura(idItemFatura) {
    return http.api.delete(`/v1/item_fatura/${idItemFatura}`);
  },

  pegarFaturaComAnalitico(id) {
    return http.api.get(`/v1/fatura/${id}?incluir=analitico`);
  },
};
