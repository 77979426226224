import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  /**
   *
   * @returns
   */
  me() {
    return http.api.get(VERSION + "/usuario/me");
  },
};
