import http from "@/plugins/axios";
import store from "@/store";

const VERSION = "/v1";

export default {
  get(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/parcela", {
      params,
    });
  },
  pausarParcela(payload) {
    let idParcela = payload.parcela_id;
    delete payload.parcela_id;
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(
      `${empresa.url_instancia}/v1/parcela/${idParcela}`,
      payload
    );
  },
  downloadBoleto(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(
      empresa.url_instancia + VERSION + "/parcela/download_boleto",
      { params }
    );
  },
};
