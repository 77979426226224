const rotas = [
  {
    path: "/configuracoes",
    name: "configs",
    component: () => import(/* webpackChunkName: "console" */ "./index.vue"),
    meta: {
      title: "Contrato",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
    children: [
      {
        path: "empresa",
        name: "empresa",
        component: () =>
          import(/* webpackChunkName: "console" */ "./empresa/index.vue"),
        meta: {
          title: "Configurações - Empresa",
          precisaAutenticacao: true,
          precisaEmpresa: true,
        },
      },
      {
        path: "fluxos_automaticos",
        name: "fluxosAutomaticos",
        component: () =>
          import(
            /* webpackChunkName: "console" */ "./fluxosAutomaticos/index.vue"
          ),
        meta: {
          title: "Configurações - Fluxos automáticos",
          precisaAutenticacao: true,
          precisaEmpresa: true,
        },
      },
      {
        path: "perfis_empresa",
        name: "perfis_empresa",
        component: () =>
          import(/* webpackChunkName: "console" */ "./perfisEmpresa/index.vue"),
        meta: {
          title: "Configurações - Perfis empresa",
          precisaAutenticacao: true,
          precisaEmpresa: true,
        },
      },
      {
        path: "faqs",
        name: "faqs",
        component: () =>
          import(/* webpackChunkName: "console" */ "./faqs/index.vue"),
        meta: {
          title: "Configurações - FAQs",
          precisaAutenticacao: true,
          precisaEmpresa: true,
        },
      },
      {
        path: "usuarios",
        name: "usuarios",
        component: () =>
          import(/* webpackChunkName: "console" */ "./usuarios/index.vue"),
        meta: {
          title: "Configurações - usuarios",
          precisaAutenticacao: true,
          precisaEmpresa: true,
        },
      },
      {
        path: "precificacao",
        name: "precificacao",
        component: () =>
          import(/* webpackChunkName: "console" */ "./precificacao/index.vue"),
        meta: {
          title: "Configurações - Precificação",
          precisaAutenticacao: true,
          precisaEmpresa: true,
        },
      },
    ],
  },
];

export { rotas };
