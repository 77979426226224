import regua from "@/services/regua";

const state = {
  reguas: null,
  reguaSelecionada: null,
  tiposRegua: [
    { tipo: "ANIVERSARIO_DO_CLIENTE" },
    { tipo: "BOLETO_SOLICITADO" },
    { tipo: "CONTRATO_FINALIZADO" },
    { tipo: "CONTRATO_CANCELADO" },
    { tipo: "IMPLANTACAO" },
    { tipo: "INICIO_CONTRATO" },
    { tipo: "MOVIMENTACAO" },
    { tipo: "PAGAMENTO_BOLETO_RECEBIDO" },
    { tipo: "PAGAMENTO_DEBITO_RECEBIDO" },
    { tipo: "PARCELA_BOLETO_CRIADA" },
    { tipo: "PARCELA_DEBITO_CRIADA" },
    { tipo: "TERMINO_CONTRATO" },
    { tipo: "TOLERANCIA_BOLETO" },
    { tipo: "TOLERANCIA_DEBITO" },
    { tipo: "VENCIMENTO_BOLETO" },
    { tipo: "VENCIMENTO_DEBITO" },
  ],
  //RESUMO CONTRATOS
  resumoContratos: null,
  erroCarregarResumoContratos: false,
  carregandoResumoContratos: false,

  desvinculandoPerfil: false,
  erroDesvincularPerfil: false,
};
const mutations = {
  setReguas: (state, payload) => {
    state.reguas = payload;
  },
  setReguaSelecionada: (state, payload) => {
    state.reguaSelecionada = payload;
  },

  //RESUMO CONTRATOS
  setResumoContratos: (state, payload) => (state.resumoContratos = payload),
  setErroCarregarResumoContratos: (state, payload) =>
    (state.erroCarregarResumoContratos = payload),
  setCarregandoResumoContratos: (state, payload) =>
    (state.carregandoResumoContratos = payload),

  setDesvinculandoPerfil: (state, payload) => {
    state.desvinculandoPerfil = payload;
  },
  setErroDesvincularPerfil: (state, payload) => {
    state.erroDesvincularPerfil = payload;
  },
};
const actions = {
  async getReguas({ commit, rootState }, payload) {
    const parametros = {
      empresa_id: rootState.empresa.empresaSelecionada.id,
      incluir: "eventos,perfis",
      ordem: "ordem",
      limite: payload.limite,
      tipo: payload.tipo,
      status: payload.status,
      tipo_movimentacao_id: payload.tipo_movimentacao_id || undefined,
    };
    const { data } = await regua.listar(parametros);
    commit("setReguas", data);
    return data;
  },
  async getRegua({ commit, rootState }, regua_id) {
    const { data } = await regua.get(regua_id);
    return data;
  },

  async carregarReguas({ commit, rootState }, payload) {
    payload = payload || {};
    payload.empresa_id = rootState.empresa.empresaSelecionada.id;

    const { data } = await regua.listar(payload);
    commit("setReguas", data);
    return data;
  },

  async saveRegua({ commit }, payload) {
    return await regua.save(payload);
  },

  async definirGanhadora({ commit }, payload) {
    try {
      return await regua.definirGanhadora(payload);
    } catch (e) {
      console.error(`Erro ao definir régua como ganhadora: ${e}`);
    }
  },

  orderReguas({ commit }, payload) {
    return regua.reorder(payload);
  },

  async carregarResumoContratos({ commit }, payload) {
    commit("setCarregandoResumoContratos", true);
    commit("setErroCarregarResumoContratos", false);
    try {
      if (!payload) {
        const resposta = await regua.resumoContratos();
        commit("setResumoContratos", resposta.data);
        return resposta.data;
      } else {
        const resposta = await regua.resumoContratos(payload);
        commit("setResumoContratos", resposta.data);
        return resposta.data;
      }
    } catch (erro) {
      console.error("Não foi possível carregar o resumo dos contratos.");
      commit("setErroCarregarResumoContratos", true);
    } finally {
      commit("setCarregandoResumoContratos", false);
    }
  },

  async vincularReguaPerfil({ commit }, payload) {
    return regua.vincularReguaPerfil(payload);
  },

  async editarReguaPerfil({ commit }, payload) {
    return await regua.editaReguaPerfil(payload);
  },

  async desvincularPerfil({ commit }, payload) {
    commit("setDesvinculandoPerfil", true);
    let idPerfis = [];

    payload.perfis.forEach((perfil) => {
      payload.regua.perfis.forEach((perfilRegua) => {
        if (
          perfilRegua.perfil_cliente === perfil.perfil_cliente &&
          perfilRegua.perfil_contrato === perfil.perfil_contrato
        ) {
          idPerfis.push(perfilRegua.id);
        }
      });
    });

    let params = {
      id_perfis: idPerfis,
      empresa_id: payload.empresa_id,
    };

    try {
      const { data } = await regua.desvincularPerfil(params);
      commit("setDesvinculandoPerfil", false);
      commit("setErroDesvincularPerfil", false);
      return data;
    } catch (error) {
      console.error(error);
      commit("setDesvinculandoPerfil", false);
      commit("setErroDesvincularPerfil", true);
    }
  },

  async getReguaBrokers({ }, regua_id) {
    const { data } = await regua.getReguaBrokers(regua_id);
    let brokers = []
    if (data) {
      brokers = Object.entries(data).map(([key, value]) => {
        return {
          [key]: value ? value.toLowerCase() : null
        }
      })
    }
    return brokers;
  },

  async postReguaBrokers({ }, payload) {
    await regua.postReguaBrokers(payload);
  },
};
const getters = {
  reguas: (state) => state.reguas,
  reguaSelecionada: (state) => state.reguaSelecionada,
  tiposRegua: (state) => (filter) => {
    const tiposRegua = state.tiposRegua.map((tipoRegua) => {
      if (filter) {
        tipoRegua.text = filter(tipoRegua.tipo);
      }
      return tipoRegua;
    });
    return tiposRegua;
  },
  //RESUMO CONTRATOS
  getResumoContratos: (state) => state.resumoContratos,
  carregandoResumoContratos: (state) => state.carregandoResumoContratos,
  erroCarregarResumoContratos: (state) => state.erroCarregarResumoContratos,
  getReguaById: (state) => (id) => {
    return state.reguas.objects.find((regua) => regua.id === id);
  },

  desvinculandoPerfil: (state) => state.desvinculandoPerfil,
  erroDesvincularPerfil: (state) => state.erroDesvincularPerfil,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
