import http from "@/plugins/axios";

export default {

  listar(params) {
    return http.api.get("v1/iniciativa_whatsapp", { params });
  },

  adicionar(payload) {
    return http.api.post(`/v1/iniciativa_whatsapp`, payload);
  },
  uploadArquivo(payload) {
    const nomeFormatado = payload.name.replace(/ /g, "_");
    const arquivo = new File([payload], nomeFormatado, { type: payload.type });

    const formData = new FormData();
    formData.append("file", arquivo);

    return http.api.post(`/v1/arquivo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  pegarUma(itemId, parametros) {
    return http.api.get(`/v1/iniciativa_whatsapp/${itemId}`, {
      params: parametros,
    });
  },
  editar(itemId, payload) {
    return http.api.patch(`/v1/iniciativa_whatsapp/${itemId}`, payload);
  },
  deletar(itemId) {
    return http.api.delete(`/v1/iniciativa_whatsapp/${itemId}`);
  },
};
