import http from "@/plugins/axios";
const resourceVersion = "/v1";

function get(payload) {
  return http.api.get(`${resourceVersion}/pesquisa`, { params: payload });
}
function getPorId(id) {
  return http.api.get(`${resourceVersion}/pesquisa/${id}`);
}
function post(payload) {
  return http.api.post(`${resourceVersion}/pesquisa`, payload);
}
function patch(payload) {
  return http.api.patch(`${resourceVersion}/pesquisa/${payload.id}`, payload);
}
function remove() {
  return http.api.delete(`${resourceVersion}/pesquisa`);
}

export default {
  get,
  getPorId,
  post,
  patch,
  remove,
};
