export default [
  {
    path: "/reguas",
    name: "Reguas",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/ListagemReguas.vue"),
    meta: {
      title: "Réguas",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/reguas/:regua_id",
    name: "eventos",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/VisualizarRegua.vue"),
    meta: {
      title: "Régua",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/evento/variaveis",
    name: "ListagemVariavelMensagem",
    component: () =>
      import(
        /* webpackChunkName: "console" */ "./pages/ListagemVariavelMensagem.vue"
      ),
    meta: {
      title: "Variáveis para as mensagens",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/regua/tipo/predefinidas",
    name: "ListagemReguasPreDefinidas",
    component: () =>
      import(
        /* webpackChunkName: "console" */ "./pages/ListagemReguasPreDefinidas.vue"
      ),
    meta: {
      title: "Tipos Réguas",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/regua/tipo/movimentacao",
    name: "ListagemReguasMovimentacao",
    component: () =>
      import(
        /* webpackChunkName: "console" */ "./pages/ListagemReguasMovimentacao.vue"
      ),
    meta: {
      title: "Tipos Réguas",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
];
