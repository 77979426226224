import faturaService from "@/services/fatura";
import router from "@/plugins/router/index.js";

const state = {
  precificacoes: [],
  faturas: [],
  totalFaturas: null,
  itensFatura: null,
  faturaParcial: null,
  faturaSelecionada: null,
  itemFaturaSelecionado: null,
  mesFaturaParcial: null,
  faturaComAnalitico: null,
  faturasComAnalitico: [],
  carregandoPrecificacoes: [],
  carregandoFaturaParcial: false,
  carregandoFaturas: false,
  carregandoItensFatura: false,
  carregandoAprovarFatura: false,
  carregandoDescartarFatura: false,
  faturaParcialNaoEncontrada: false,
  erroCarregarPrecificacoes: false,
  erroCarregarFaturaParcial: false,
  erroCarregarFaturas: false,
  erroCarregarItensFatura: false,
  erroCarregarAprovarFatura: false,
};

const mutations = {
  setPrecificacoes(state, payload) {
    state.precificacoes = payload;
  },
  setItensFatura(state, payload) {
    state.itensFatura = payload;
  },
  setFaturaParcial(state, payload) {
    state.faturaParcial = payload;
  },
  setFaturas(state, payload) {
    state.faturas = payload.objects;
  },
  setTotalFaturas(state, payload) {
    state.totalFaturas = payload;
  },
  setMesFaturaParcial(state, payload) {
    state.mesFaturaParcial = payload;
  },
  setFaturaSelecionada(state, payload) {
    state.faturaSelecionada = payload;
  },
  setItemFaturaSelecionado(state, payload) {
    state.itemFaturaSelecionado = payload;
  },
  setCarregandoFaturaParcial(state, payload) {
    state.carregandoFaturaParcial = payload;
  },
  setCarregandoPrecificacoes(state, payload) {
    state.carregandoPrecificacoes = payload;
  },
  setErroCarregarFaturaParcial(state, payload) {
    state.erroCarregarFaturaParcial = payload;
  },
  setFaturaParcialNaoEncontrada(state, payload) {
    state.faturaParcialNaoEncontrada = payload;
  },
  setFaturaComAnalitico(state, fatura) {
    state.faturaComAnalitico = fatura;
  },
  setFaturasComAnalitico(state, fatura) {
    state.faturasComAnalitico = [...state.faturasComAnalitico, fatura];
  },
  setCarregandoFaturas(state, payload) {
    state.carregandoFaturas = payload;
  },
  setCarregandoAprovarFatura(state, payload) {
    state.carregandoAprovarFatura = payload;
  },
  setCarregandoItensFatura(state, payload) {
    state.carregandoItensFatura = payload;
  },
  setCarregandoDescartarFatura(state, payload) {
    state.carregandoDescartarFatura = payload;
  },
  setErroCarregarItensFatura(state, payload) {
    state.erroCarregarItensFatura = payload;
  },
  setErroCarregarFaturas(state, payload) {
    state.erroCarregarFaturas = payload;
  },
  setErroCarregarPrecificacoes(state, payload) {
    state.erroCarregarPrecificacoes = payload;
  },
  atualizaListaFatura(state, payload) {
    let verificaSeExiste = state.faturas.find((item) => item.id == payload.id);
    if (verificaSeExiste) {
      let index = state.faturas.indexOf(verificaSeExiste);
      state.faturas[index] = payload;
      state.faturas = JSON.parse(JSON.stringify(state.faturas));
    } else {
      state.faturas.push(payload);
    }
  },
  atualizaListaItemFatura(state, payload) {
    let verificaSeExiste = state.itensFatura.find(
      (fatura) => fatura.id == payload.id
    );
    if (verificaSeExiste) {
      let index = state.itensFatura.indexOf(verificaSeExiste);
      state.itensFatura[index] = payload;
      state.itensFatura = JSON.parse(JSON.stringify(state.itensFatura));
    } else {
      state.itensFatura.push(payload);
    }
  },
};

const actions = {
  async getItensFatura({ commit }, payload) {
    if (!payload.naoCommitar) commit("setCarregandoItensFatura", true);
    try {
      const resposta = await faturaService.getItemsFatura({
        ...payload,
        naoCommitar: undefined,
      });
      if (payload.naoCommitar) {
        return resposta;
      }
      commit("setMesFaturaParcial", resposta.data.mes_fatura);
      commit("setItensFatura", resposta.data.objects);
      commit("setCarregandoItensFatura", false);
      commit("setErroCarregarItensFatura", false);
    } catch (error) {
      console.error(error);
      commit("setCarregandoItensFatura", false);
      commit("setErroCarregarItensFatura", true);
    }
  },
  async getPrecificacoes({ commit }, payload) {
    commit("setCarregandoPrecificacoes", true);
    try {
      const resposta = await faturaService.getPrecificacao({
        ...payload,
      });
      const respostaFormatada = resposta.data.objects.map((item) => {
        return {
          chave: item?.categoria,
          nome: item?.categoria_apelido,
          cor: item?.cor,
        };
      });
      commit("setPrecificacoes", respostaFormatada);
      commit("setCarregandoPrecificacoes", false);
      commit("setErroCarregarPrecificacoes", false);
    } catch (error) {
      console.error(error);
      commit("setCarregandoPrecificacoes", false);
      commit("setErroCarregarItensFatura", true);
    }
  },
  async getFaturaParcial({ commit }, payload) {
    commit("setCarregandoFaturaParcial", true);
    await faturaService
      .getFaturaAtual(payload)
      .then((resposta) => {
        commit("setFaturaParcial", resposta.data);
        commit("setCarregandoFaturaParcial", false);
        commit("setErroCarregarFaturaParcial", false);
        commit("setFaturaParcialNaoEncontrada", false);
      })
      .catch((error) => {
        console.error(error);
        const e = JSON.parse(JSON.stringify(error));
        commit("setCarregandoFaturaParcial", false);
        if (e.status === 500) commit("setErroCarregarFaturaParcial", true);
        else commit("setFaturaParcialNaoEncontrada", true);
      });
  },
  async getTodasFaturas({ commit }, payload) {
    commit("setCarregandoFaturas", true);
    commit("setFaturas", []);
    try {
      const { data } = await faturaService.getFatura(payload);
      commit("setFaturas", data);
      commit("setTotalFaturas", data.num_results);
      commit("setCarregandoFaturas", false);
      commit("setErroCarregarFaturas", false);
      return data;
    } catch (error) {
      console.error(error);
      commit("setCarregandoFaturas", false);
      commit("setErroCarregarFaturas", true);
      Promise.reject(error);
    }
  },
  limparFaturas({ commit }) {
    commit("setFaturas", []);
  },
  async enviarFaturaCredor({}, payload) {
    await faturaService.enviarFatura(payload);
  },
  async editaFatura({ dispatch, commit }, payload) {
    await faturaService.editaFatura(payload).then((retorno) => {
      if (retorno) {
        dispatch(
          "snackbar/showSnackBar",
          {
            text: `Sucesso ao editar fatura`,
          },
          { root: true }
        );
        commit("atualizaListaFatura", retorno.data);
        commit("setFaturaSelecionada", retorno);
      } else {
        dispatch(
          "snackbar/showSnackBar",
          {
            text: `Erro ao editar fatura`,
          },
          { root: true }
        );
      }
    });
  },
  async editaOuCriaItemFatura({ commit }, payload) {
    await faturaService.criaOuEditaItemFatura(payload).then((retorno) => {
      commit("atualizaListaItemFatura", retorno.data);
      commit("setItemFaturaSelecionado", retorno);
    });
  },
  async deletaItemFatura({ commit }, item_fatura) {
    await faturaService.deletaItemFatura(item_fatura.id).then(() => {
      let index = state.itensFatura.indexOf(item_fatura);
      state.itensFatura.splice(index, 1);
    });
  },
  async aprovarFatura({ commit }, faturaId) {
    commit("setCarregandoAprovarFatura", true);
    await faturaService
      .aprovarFatura(faturaId)
      .then((retorno) => {
        commit("setCarregandoAprovarFatura", false);
        commit("atualizaListaFatura", retorno.data);
        commit("setFaturaSelecionada", retorno.data);
      })
      .catch(() => {
        commit("setCarregandoAprovarFatura", false);
      });
  },
  async descartarFatura({ commit, dispatch, rootGetters }, faturaId) {
    commit("setCarregandoDescartarFatura", true);
    await faturaService.descartarFatura(faturaId).then(() => {
      let empresa = rootGetters["empresa/empresaSelecionada"];
      let payload = {
        empresa_id: empresa.id,
        status: "APROVADA,PAGA,PAGO_PARCIAL,ABERTA",
        pagina: router.currentRoute.query.page,
        ordem: "-id",
        limite: router.currentRoute.query.itemsPerPage,
      };
      dispatch("getTodasFaturas", payload);
      commit("setCarregandoDescartarFatura", false);
    });
  },
  async pegarFaturaComAnalitico({ state, commit, getters }, id) {
    if (state.faturasComAnalitico?.some((fatura) => fatura?.id === id)) {
      let fatura = getters.faturasComAnalitico?.find(
        (fatura) => fatura?.id === id
      );
      commit("setFaturaComAnalitico", fatura);
      return fatura;
    }
    let resposta = await faturaService
      .pegarFaturaComAnalitico(id)
      .then((retorno) => {
        if (retorno?.status == 200) {
          return retorno.data;
        } else {
          return null;
        }
      });

    commit("setFaturaComAnalitico", resposta);
    commit("setFaturasComAnalitico", resposta);
    return resposta;
  },
};

const getters = {
  precificacoes: (state) => state.precificacoes,
  itensFatura: (state) => state.itensFatura,
  faturaParcial: (state) => state.faturaParcial,
  faturas: (state) => state.faturas,
  mesFaturaParcial: (state) => state.mesFaturaParcial,
  itemFaturaSelecionado: (state) => state.itemFaturaSelecionado,
  carregandoFaturaParcial: (state) => state.carregandoFaturaParcial,
  erroCarregarFaturaParcial: (state) => state.erroCarregarFaturaParcial,
  faturaParcialNaoEncontrada: (state) => state.faturaParcialNaoEncontrada,
  carregandoFaturas: (state) => state.carregandoFaturas,
  erroCarregarFaturas: (state) => state.erroCarregarFaturas,
  carregandoItensFatura: (state) => state.carregandoItensFatura,
  erroCarregarItensFatura: (state) => state.erroCarregarItensFatura,
  carregandoAprovarFatura: (state) => state.carregandoAprovarFatura,
  carregandoDescartarFatura: (state) => state.carregandoDescartarFatura,
  totalFaturas: (state) => state.totalFaturas,
  faturaComAnalitico: (state) => state.faturaComAnalitico,
  faturasComAnalitico: (state) => state.faturasComAnalitico,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
