import service from "@/services/nps.js";

const state = {
  listaNps: [],
  carregando: false,
};
const mutations = {
  setListaNps(state, payload) {
    state.listaNps = payload;
  },
  setCarregando(state, payload) {
    state.carregando = payload;
  },
  setItemListaNps(state, payload) {
    let lista = [...state.listaNps];
    let itemIndex = lista.findIndex((item) => item.id == payload.id);

    if (itemIndex >= 0) {
      lista[itemIndex] = payload;
    } else {
      lista.push(payload);
    }

    state.listaNps = lista;
  },
};
const actions = {
  async pegar({ commit, dispatch }, parametros) {
    commit("setCarregando", true);
    await service
      .getNps(parametros)
      .then(({ data }) => {
        commit("setListaNps", data);
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          "snackbar/showSnackBar",
          {
            text: `Erro ao retornar itens`,
          },
          { root: true }
        );
        return error;
      })
      .finally(() => {
        commit("setCarregando", false);
      });
  },
  async criar({ dispatch, commit }, item) {
    commit("setCarregando", true);

    return service
      .adicionarItem(item)
      .then((resposta) => {
        if (resposta) {
          dispatch(
            "snackbar/showSnackBar",
            {
              text: `Sucesso ao criar item`,
            },
            { root: true }
          );
          commit("setItemListaNps", resposta.data);
        } else {
          dispatch(
            "snackbar/showSnackBar",
            {
              text: `Erro ao criar item`,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("setCarregando", false);
      });
  },
  async editar({ dispatch, commit }, variavel) {
    commit("setCarregando", true);
    return service
      .editarItem({ ...variavel })
      .then((resposta) => {
        if (resposta) {
          dispatch(
            "snackbar/showSnackBar",
            {
              text: `Sucesso ao alterar item`,
            },
            { root: true }
          );
          commit("setItemListaNps", resposta.data);
        } else {
          dispatch(
            "snackbar/showSnackBar",
            {
              text: `Erro ao alterar item`,
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("setCarregando", false);
      });
  },
};
const getters = {
  listaNps: (state) => state.listaNps,
  carregando: (state) => state.carregando,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
