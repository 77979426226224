import http from "@/plugins/axios";
const resourceVersion = "/v1";

function get(payload) {
  return http.api.get(`${resourceVersion}/template_email`, { params: payload });
}
function getPorId(id) {
  return http.api.get(`${resourceVersion}/template_email`, {
    params: { id: id },
  });
}
function salvar(payload, id) {
  if (id) {
    return http.api.patch(`${resourceVersion}/template_email/${id}`, payload);
  } else return http.api.post(`${resourceVersion}/template_email`, payload);
}

function alteraStatus(payload) {
  return http.api.patch(`${resourceVersion}/template_email/${payload.id}`, {
    status: payload.status,
  });
}
function validar(payload) {
  return http.api.post(`${resourceVersion}/template_email/validar`, payload);
}
function publicar(payload) {
  return http.api.post(
    `${resourceVersion}/template_email/${payload.id}/publicar`,
    payload
  );
}
function despublicar(payload) {
  return http.api.post(
    `${resourceVersion}/template_email/${payload.id}/despublicar`,
    payload
  );
}
function renderizar(id, payload) {
  return http.api.post(
    `${resourceVersion}/template_email/${id}/renderizar`,
    payload
  );
}
function arquivar(id) {
  return http.api.post(`${resourceVersion}/template_email/${id}/arquivar`);
}
function remove() {
  return http.api.delete(`${resourceVersion}/template_email`);
}
function salvarAnexo(payload) {
  return http.api.post(`${resourceVersion}/anexo_email`, payload);
}
function removeAnexo(id) {
  return http.api.delete(`${resourceVersion}/anexo_email/${id}`);
}
function getAnexo(id) {
  return http.api.get(`${resourceVersion}/anexo_email`, {
    params: { template_email_id: id },
  });
}
export default {
  get,
  getPorId,
  salvar,
  validar,
  publicar,
  despublicar,
  renderizar,
  arquivar,
  remove,
  alteraStatus,
  salvarAnexo,
  removeAnexo,
  getAnexo,
};
