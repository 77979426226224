export default [
  {
    path: "/contratos/:id",
    name: "VerContrato",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/VerContrato.vue"),
    meta: {
      title: "Contrato",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/cliente/:id",
    name: "VerCliente",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/VerCliente.vue"),
    meta: {
      title: "Cliente",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/graficos",
    name: "Graficos",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/Contratos.vue"),
    meta: {
      title: "Contratos",
      precisaAutenticacao: true,
      precisaEmpresa: true
    },
  },
  {
    path: "/notificacoes",
    name: "Notificacoes",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/VerNotificacoes.vue"),
    meta: {
      title: "Notificações",
      precisaAutenticacao: true,
      precisaEmpresa: true
    },
  },
];
