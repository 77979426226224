import store from "../../store/index";

export const checkCredentials = function (payload) {
  const grantedCredentials = payload;
  const { permissoes: credentials } = store.getters["auth/getUsuario"];
  let granted = false;
  try {
    credentials.forEach((credential) => {
      if (!grantedCredentials.some((rule) => rule == credential)) return;
      granted = true;
    });
    return granted;
  } catch {
    return false;
  }
};

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function formataCasasDecimais(value) {
  if (!value) return 0;
  if ((typeof value !== "number" && typeof value !== "string") || isNaN(value))
    return 0;

  const valorDecimal = parseFloat(value);
  const decimais = String(valorDecimal).split(".");

  if (decimais[1]) {
    const arrDecimais = Array.from(decimais[1]);
    for (let i = arrDecimais.length + 1; i == 0; i--) {
      if (arrDecimais[i] === 0) arrDecimais.pop();
      else break;
    }
  }

  const numAbsoluto = Math.abs(decimais[0]);
  let numDecimal = 0;

  if (!decimais[1]) {
    numDecimal = "00";
  } else {
    decimais[1].length < 2
      ? (numDecimal = decimais[1] + "0")
      : (numDecimal = decimais[1]);
  }

  if (!decimais[1]) {
    return parseFloat(`${numAbsoluto}.${numDecimal}`).toFixed(2);
  }

  return parseFloat(`${numAbsoluto}.${numDecimal}`);
}


export function stringToColor(str) {
  if (!str) return
  str = str.toLowerCase()
  if (!str.includes('hsl')){
    str = str.split('_')[0]
  }
  function adjustHue(color, degrees) {
    const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
    const matches = regex.exec(color);
    if (!matches) return null;

    const [_, r, g, b] = matches;
    const hslColor = `hsl(${(parseInt(r, 16) + degrees) % 360}, ${g}, ${b})`;
    const newColor = hslColor;
    return newColor;
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = "#" + (hash & 0x00ffffff).toString(16).toUpperCase();

  if (hasGoodContrastWithWhite(color)) return color;
  const newColor = adjustHue(color, 35);
  return this.stringToColor(newColor);
}

function getRelativeLuminance(color) {
  const gammaCorrection = (value) => {
    value = value / 255;
    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4);
  };

  const r = gammaCorrection((color >> 16) & 0xff);
  const g = gammaCorrection((color >> 8) & 0xff);
  const b = gammaCorrection(color & 0xff);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function hasGoodContrastWithWhite(color) {
  const hexColor = parseInt(color.slice(1), 16);
  const contrastRatio = (getRelativeLuminance(hexColor) + 0.05) / 1.05;
  return contrastRatio < 1 / 7;
}