import eventos from "@/services/eventos";

const state = {
  eventos: {
    objetos: new Array(),
  },
  eventoSelecionado: null,
};
const mutations = {
  setEventos(state, payload) {
    state.eventos = payload;
  },
  setSelected(state, payload) {
    state.selecionado = payload;
  },
};
const actions = {
  async getTodos({ commit }, payload) {
    const { data } = await eventos.get(payload);
    commit("setEventos", data);
  },
};
const getters = {
  eventos: (state) => state.eventos,
  eventoSelecionado: (state) => state.selecionado,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
