import Vue from "vue";
import axios from "axios";
import environment from "@/assets/js/enviroment";
import Qs from "qs";
import Cookies from 'js-cookie'
import store from "@/store";

Vue.prototype.$http = axios;

const apis = {
  apiCore: axios.create({
    baseURL: environment.coreApiUrl,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: "repeat" });
    },
  }),
  dadosApi: axios.create({
    baseURL: environment.dadosUrl,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: "repeat" });
    },
  }),
};

const apiKeys = Object.keys(apis);

const setTokenCoreApi = (token) => {
  apis.apiCore.defaults.headers["authentication-token"] = token;
};

const setTokenDadosApi = (token) => {
  apis.dadosApi.defaults.headers["authentication-token"] = token;
};

const setErrorInterceptor = (api) => {
  api.interceptors.response.use(
    (response) => { return response },
    (error) => {
      if (error?.response?.status === 401) {
        store.dispatch("auth/logout", { root: true });
      }
      return Promise.reject(error);
    }
  );
};

apiKeys.forEach((api) => {
  setErrorInterceptor(apis[api]);
});

export default {
  setTokenCoreApi,
  setTokenDadosApi,
  apiDados: apis.dadosApi,
  api: apis.apiCore,
  generic: axios,
};
