export default [
  {
    path: "/dashboard/:id*",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "console" */ "./pages/Dashboard"),
    meta: {
      title: "Dashboard",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
];
