import http from "@/plugins/axios";

export const FluxosService = {
  get({ params, empresaId } = {}) {
    return http.api.get(`/v1/empresa/${empresaId}/fluxo_automatico`, {
      params: params,
    });
  },
  patch({ params, empresaId, fluxoId } = {}) {
    return http.api.patch(
      `/v1/empresa/${empresaId}/fluxo_automatico/${fluxoId}`,
      {
        ...params,
      }
    );
  },
  uploadArquivo(payload) {
    const nomeFormatado = payload.name.replace(/ /g, "_");
    const arquivo = new File([payload], nomeFormatado, { type: payload.type });

    const formData = new FormData();
    formData.append("file", arquivo);

    return http.api.post(`/v1/arquivo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
