import Vue from "vue";
import store from "@/store";
import routes from "./routes";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    ...routes,
    {
      path: "/",
      name: "Home",
      redirect: "/regua/tipo/predefinidas",
    },
    {
      path: "/autenticar",
      name: "Autenticar",
      beforeEnter: async (to, from, next) => {
        await store.dispatch("auth/auth", to.query.token);
        next('/')
      },
      meta: {
        precisaAutenticacao: false,
      },
    },
  ],
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - Console do MeLembre";
  } else {
    document.title = "Console do MeLembre";
  }

  const precisaAutenticacao = !!to.meta.precisaAutenticacao;
  const precisaEmpresa = !!to.meta.precisaEmpresa;

  if (!precisaAutenticacao) return next();

  try {
    const isAutenticado = await store.dispatch("auth/verificarAutenticacao")
    if (!isAutenticado) return next({ name: "Autenticar" });

    let empresas = store.state.auth.usuario.empresas;

    if (to.query?.empresa) {
      const empresa = empresas.find((e) => e?.chave == to.query?.empresa);
      if (empresa) store.dispatch("empresa/setEmpresaSelecionada", empresa);
    }

    if (precisaEmpresa && empresas.length == 0) {
      console.debug("Nenhuma empresa configurada.");
      return next({ name: "AcessoNaoConfigurado" });
    }

    return next();
  } catch {
    return next({ name: "Erro" });
  }
});

router.onError((error) => {
  console.error("Não foi possível carregar a rota.", error);
});

window.$router = router;

export default router;
