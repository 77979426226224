<template>
  <v-snackbar
    v-bind="snackbar.attrs"
    :timeout="snackbar.timeout"
    v-model="active"
  >
    {{ snackbar.text }}
    <v-icon class="ml-8" v-if="snackbar.type" :color="snackbar.type">
      {{ snackbar.type == "error" ? "mdi-alert-circle" : "mdi-check-bold" }}
    </v-icon>
    <template v-if="snackbar.closable" v-slot:action="{ attrs }">
      <v-btn icon color="pink" text v-bind="attrs" @click="active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations("snackbar", {
      setActive: "setActive",
    }),
  },
  computed: {
    ...mapGetters("snackbar", {
      snackbar: "snackbar",
    }),
    active: {
      get() {
        return this.snackbar.active;
      },
      set(empresa) {
        this.setActive(empresa);
      },
    },
  },
};
</script>

<style></style>
