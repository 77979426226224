import agendaService from "@/services/agenda";

const state = {
  agendas: null,
  carregando: false,
  erroCarregarAgendas: false,
};

const mutations = {
  setAgendas: (state, payload) => {
    state.agendas = payload;
  },
  setCarregando: (state, payload) => {
    state.carregando = payload;
  },
  pushAgenda(state, payload) {
    let verifyIfExist = state.agendas.find((agenda) => agenda.id == payload.id);
    if (verifyIfExist) {
      let index = state.agendas.indexOf(verifyIfExist);
      state.agendas[index] = payload;
      state.agendas = JSON.parse(JSON.stringify(state.agendas));
    } else {
      state.agendas.push(payload);
    }
  },
  setErroCarregarAgendas(state, payload) {
    state.erroCarregarAgendas = payload;
  },
};

const actions = {
  async getAgendas({ commit }, payload) {
    commit("setCarregando", true);

    await agendaService
      .getAgenda(payload)
      .then((resposta) => {
        commit("setAgendas", resposta.data.objects);
        commit("setErroCarregarAgendas", false);
        return resposta.data.objects;
      })
      .catch((error) => {
        commit("setErroCarregarAgendas", true);
        console.error(error);
      })
      .finally(() => {
        commit("setCarregando", false);
      });
  },

  async criaOuEditaAgenda({ commit }, payload) {
    if (payload.id) {
      const retorno = await agendaService.editarAgenda(payload);
      commit("pushAgenda", retorno.data);
      return retorno.data;
    } else {
      const retorno = await agendaService.cadastrarAgenda(payload);
      commit("pushAgenda", retorno.data);
      return retorno.data;
    }
  },
};
const getters = {
  agendas: (state) => state.agendas,
  carregando: (state) => state.carregando,
  erroCarregarAgendas: (state) => state.erroCarregarAgendas,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
