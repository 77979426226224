import empresaService from "@/services/empresa";
import { checkCredentials } from "@/assets/js/utils";
import Cookies from "js-cookie";
import enviroment from "@/assets/js/enviroment";

const CHAVE_EMPRESA_SELECIONADA = "meLembreEmpresaSelecionada";

const state = {
  empresas: null,
  empresaSelecionada: {},
  empresaEmailsCobranca: null,
};
const mutations = {
  setEmpresas: (state, empresas) => {
    state.empresas = empresas;
    if (empresas?.length) {

      let empresaSelecionada = empresas.find(
        (empresa) => empresa.id == state.empresaSelecionada.id
      );

      state.empresaSelecionada = empresaSelecionada ?? empresas[0];

      Cookies.set(
        CHAVE_EMPRESA_SELECIONADA,
        state.empresaSelecionada.chave,
        {
          domain: enviroment.cookiesDomain,
          path: "/",
        }
      );
    }
  },
  setEmpresaSelecionada: (state, payload) => {
    state.empresaSelecionada = payload;

    Cookies.set(
      CHAVE_EMPRESA_SELECIONADA,
      state.empresaSelecionada.chave,
      {
        domain: enviroment.cookiesDomain,
        path: "/",
      }
    );
  },
  setEmpresaEmailsCobranca: (state, payload) => {
    state.empresaEmailsCobranca = payload;
  },
  setEmpresaBrokers: (state, payload) => {
    state.empresaSelecionada.brokers = payload
  }
};
const actions = {
  async getEmpresa({ commit }, empresa) {
    commit("setEmpresaEmailsCobranca", null);
    await empresaService.getEmpresaPorId(empresa).then((retorno) => {
      let emails = [];
      if (!checkCredentials(["admin", "editor_fatura"])) {
        retorno.data.emails_cobranca.forEach((email) => {
          if (!email.interno) {
            emails.push(email);
          }
        });
      } else {
        emails = retorno.data.emails_cobranca;
      }
      commit("setEmpresaEmailsCobranca", emails);
    });
  },

  async getEmpresaById({ }, { empresa, params }) {
    try {
      const { data } = await empresaService.getEmpresaPorId(empresa, params);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async salvarEmpresa({ rootState }, params) {
    try {
      const empresa_id = rootState.empresa?.empresaSelecionada.id;
      const fieldsToDelete = [
        "bucket",
        "chave",
        "criado_em",
        "dia_fim_fatura",
        "dia_inicio_fatura",
        "grupo_faturamento",
        "id",
        "rate_limit_email",
        "url_instancia",
      ];
      fieldsToDelete.forEach((field) => delete params[field]);
      const { data } = await empresaService.editarEmpresa(empresa_id, params);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editaEmailsCobranca({ commit }, payload) {
    await empresaService.editarEmailCobranca(payload).then((retorno) => {
      commit("setEmpresaEmailsCobranca", retorno.data.emails_cobranca);
    });
  },

  async setEmpresaSelecionada({ commit }, payload) {
    const empresaSelecionada = payload
    try {
      const { data } = await empresaService.getEmpresaBrokers(payload.id)
      if (data) {
        empresaSelecionada.brokers = data
      }
    } catch (e) {
      console.error("Erro ao carregar brokers da empresa:", e)
    } finally {
      commit("setEmpresaSelecionada", payload)
    }
  }
};
const getters = {
  empresas: (state) => state.empresas,
  empresaSelecionada: (state) => state.empresaSelecionada,
  empresaEmailsCobranca: (state) => state.empresaEmailsCobranca,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
