import Vue from "vue";

const state = {
  filtrosAplicados: [],

  filtrosTipoEvento: [],
  filtrosTipoNotificacao: [],
  filtrosStatusNotificacao: [],
  filtrosData: null,
  dataFiltrada: [],
  filtroContrato: [],
  filtrosTipoRegua: [],
};
const mutations = {
  setFiltrosAplicados(state, payload) {
    if (payload) {
      state.filtrosAplicados = payload;
      return;
    }
    state.dataFiltrada = [];
    if (state.filtrosData) state.dataFiltrada.push(state.filtrosData);
    state.filtrosAplicados = [
      ...state.filtrosTipoEvento,
      ...state.filtrosTipoNotificacao,
      ...state.filtrosStatusNotificacao,
      ...state.dataFiltrada,
      ...state.filtroContrato,
      ...state.filtrosTipoRegua,
    ];
  },

  setFiltrosTipoEvento(state, payload) {
    state.filtrosTipoEvento = payload.map((filtro) => {
      return {
        nome: Vue.filter("tipoEvento")(filtro),
        valor: filtro,
        tipoFiltro: "EVENTO",
      };
    });
  },

  setFiltrosTipoRegua(state, payload) {
    state.filtrosTipoRegua = payload.map((filtro) => {
      return {
        nome: Vue.filter("tipoRegua")(filtro),
        valor: filtro,
        tipoFiltro: "REGUA",
      };
    });
  },

  setFiltrosTipoNotificacao(state, payload) {
    state.filtrosTipoNotificacao = payload.map((filtro) => {
      return {
        nome: Vue.filter("tipoNotificacao")(filtro),
        valor: filtro,
        tipoFiltro: "NOTIFICACAO",
      };
    });
  },

  setFiltrosStatusNotificacao(state, payload) {
    state.filtrosStatusNotificacao = payload.map((filtro) => {
      return {
        nome: Vue.filter("statusNotificacao")(filtro),
        valor: filtro,
        tipoFiltro: "STATUS_NOTIFICACAO",
      };
    });
  },

  setFiltrosData(state, payload) {
    let verificaOrdemData = (data) => {
      if (!data[1]) return data;
      if (data[0] > data[1]) {
        data.splice(0, 2, data[1], data[0]);
        return data;
      } else {
        return data;
      }
    };
    state.filtrosData = {
      valor: verificaOrdemData(payload),
      nome: payload[1] ? `${payload[0]} ~ ${payload[1]}` : `${payload[0]}`,
      tipoFiltro: "DATA",
    };
  },
  setFiltroContrato(state, payload) {
    state.filtroContrato = [
      {
        nome: `${payload.tipo} - #${payload.id}`,
        valor: payload,
        tipoFiltro: "CONTRATO",
      },
    ];
  },
};
const actions = {
  removeFiltros({ commit, state }, filtro) {
    if (filtro.filtroTipo == "CONTRATO") {
      delete state.filtroContrato[state.filtroContrato.indexOf(filtro)];
    }
    if (filtro.tipoFiltro === "EVENTO") {
      let index = state.filtrosTipoEvento.findIndex((filtroSelecionado) => {
        return filtroSelecionado.nome === filtro.nome;
      });
      state.filtrosTipoEvento.splice(index, 1);
      commit("setFiltrosAplicados");
    } else if (filtro.tipoFiltro === "NOTIFICACAO") {
      let index = state.filtrosTipoNotificacao.findIndex(
        (filtroSelecionado) => {
          return filtroSelecionado.nome === filtro.nome;
        }
      );
      state.filtrosTipoNotificacao.splice(index, 1);
      commit("setFiltrosAplicados");
    } else if (filtro.tipoFiltro === "STATUS_NOTIFICACAO") {
      let index = state.filtrosStatusNotificacao.findIndex(
        (filtroSelecionado) => {
          return filtroSelecionado.nome === filtro.nome;
        }
      );
      state.filtrosStatusNotificacao.splice(index, 1);
      commit("setFiltrosAplicados");
    } else if (filtro.tipoFiltro === "REGUA") {
      let index = state.filtrosTipoRegua.findIndex((filtroSelecionado) => {
        return filtroSelecionado.nome === filtro.nome;
      });
      state.filtrosTipoRegua.splice(index, 1);
      commit("setFiltrosAplicados");
    } else if (filtro.tipoFiltro === "DATA") {
      state.filtrosData = null;
      let index = state.dataFiltrada.findIndex((filtroSelecionado) => {
        return filtroSelecionado.nome === filtro.nome;
      });
      state.dataFiltrada.splice(index, 1);
      commit("setFiltrosAplicados");
    } else {
      let index = state.filtroContrato.findIndex((filtroSelecionado) => {
        return filtroSelecionado.nome === filtro.nome;
      });
      state.filtroContrato.splice(index, 1);
      commit("setFiltrosAplicados");
    }
  },
};
const getters = {
  filtrosTipoEvento: (state) => state.filtrosTipoEvento,
  filtrosTipoNotificacao: (state) => state.filtrosTipoNotificacao,
  filtrosStatusNotificacao: (state) => state.filtrosStatusNotificacao,
  filtrosData: (state) => state.filtrosData,
  filtroContrato: (state) => state.filtroContrato,
  filtrosTipoRegua: (state) => state.filtrosTipoRegua,
  todosFiltros: (state) => state.filtrosAplicados,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
