import contatoService from "../services/contato";
import Vue from "vue";

const state = {
  emails: null,
  telefones: null,
  sms: null,
  ligacao: null,
  whatsapp: null,
  push: null,
  carregandoCanais: false,
  erroCarregarCanais: false,
};

const mutations = {
  setEmails: (state, payload) => {
    state.emails = payload;
  },
  setTelefones: (state, payload) => {
    state.telefones = payload;
  },
  setCarregandoCanais: (state, payload) => {
    state.carregandoCanais = payload;
  },
  setErroCarregarCanais: (state, payload) => {
    state.erroCarregarCanais = payload;
  },
  updateEmail(state, payload) {
    const index = state.emails.findIndex((email) => email.id == payload.id);
    if (index == -1) return;
    Vue.set(state.emails, index, payload);
  },
  updateTelefone(state, payload) {
    const index = state.telefones.findIndex(
      (telefone) => telefone.id == payload.id
    );
    if (index == -1) return;
    Vue.set(state.telefones, index, payload);
  },
  addEmail(state, payload) {
    let newEmails = state.emails;
    newEmails.push(payload);
    Vue.set(state.emails, newEmails);
  },
  addTelefone(state, payload) {
    let newTelefones = state.telefones;
    newTelefones.push(payload);
    Vue.set(state.telefones, newTelefones);
  },
  setSms(state, payload) {
    state.sms = payload;
  },
  setLigacao(state, payload) {
    state.ligacao = payload;
  },
  setWhatsapp(state, payload) {
    state.whatsapp = payload;
  },
  setPush(state, payload) {
    state.push = payload;
  },
};
const actions = {
  async atualizaEmail({}, payload) {
    try {
      const { data } = await contatoService.atualizarEmail(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async getEmails({ commit }, params) {
    commit("setCarregandoCanais", true);
    await contatoService
      .getEmail(params)
      .then((response) => {
        commit("setErroCarregarCanais", false);
        commit("setEmails", response?.data?.objects);
      })
      .catch((error) => {
        console.log(error);
        commit("setErroCarregarCanais", true);
      })
      .finally(() => {
        commit("setCarregandoCanais", false);
      });
  },
  async atualizaSms({}, payload) {
    try {
      const { data } = await contatoService.atualizarSms(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async getSms({ commit }, params) {
    commit("setCarregandoCanais", true);
    await contatoService
      .getSms(params)
      .then((response) => {
        commit("setSms", response?.data?.objects);
      })
      .catch((error) => {
        console.log(error);
        commit("setErroCarregarCanais", true);
      })
      .finally(() => {
        commit("setCarregandoCanais", false);
      });
  },
  async atualizaLigacao({}, payload) {
    try {
      const { data } = await contatoService.atualizarLigacao(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async getLigacao({ commit }, params) {
    commit("setCarregandoCanais", true);
    await contatoService
      .getLigacao(params)
      .then((response) => {
        commit("setLigacao", response?.data?.objects);
      })
      .catch((error) => {
        console.log(error);
        commit("setErroCarregarCanais", true);
      })
      .finally(() => {
        commit("setCarregandoCanais", false);
      });
  },
  async atualizaWhatsapp({}, payload) {
    try {
      const { data } = await contatoService.atualizarWhatsapp(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async getWhatsapp({ commit }, params) {
    commit("setCarregandoCanais", true);
    await contatoService
      .getWhatsapp(params)
      .then((response) => {
        commit("setWhatsapp", response?.data?.objects);
      })
      .catch((error) => {
        console.log(error);
        commit("setErroCarregarCanais", true);
      })
      .finally(() => {
        commit("setCarregandoCanais", false);
      });
  },
  async getPush({ commit }, params) {
    commit("setCarregandoCanais", true);
    await contatoService
      .getPush(params)
      .then((response) => {
        commit("setPush", response?.data?.objects);
      })
      .catch((error) => {
        console.log(error);
        commit("setErroCarregarCanais", true);
      })
      .finally(() => {
        commit("setCarregandoCanais", false);
      });
  },
};
const getters = {
  telefones: (state) => state.telefones,
  emails: (state) => state.emails,
  sms: (state) => state.sms,
  ligacao: (state) => state.ligacao,
  whatsapp: (state) => state.whatsapp,
  push: (state) => state.push,
  erroCarregarCanais: (state) => state.erroCarregarCanais,
  carregandoCanais: (state) => state.carregandoCanais,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
