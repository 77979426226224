import perfilEmpresaService from "@/services/perfisEmpresa";

const state = {
  perfisEmpresa: [],
};
const mutations = {
  setPerfisEmpresa(state, payload) {
    state.perfisEmpresa = payload;
  },
  adicionaPerfilEmpresa(state, payload) {
    state.perfisEmpresa.unshift(payload);
  },
  replacePerfilEmpresa(state, payload) {
    const index = state.perfisEmpresa.findIndex(
      (perfil) => perfil.chave == payload.chave
    );
    state.perfisEmpresa.splice(index, 1, payload);
  },
};
const actions = {
  async buscaperfisEmpresa({ commit, rootState }, payload) {
    const { id: empresaId } = rootState.empresa?.empresaSelecionada;
    const params = { ...payload, empresa_id: empresaId };
    try {
      const { data } = await perfilEmpresaService.get(params);
      commit("setPerfisEmpresa", data.objects);
      return data.objects;
    } catch (e) {
      console.error(e);
      Promise.reject(e);
    }
  },
  async criaPerfilEmpresa({ commit, rootState }, payload) {
    const { id: empresaId } = rootState.empresa?.empresaSelecionada;
    const params = { ...payload, empresa_id: empresaId };
    try {
      const { data } = await perfilEmpresaService.post(params);
      commit("adicionaPerfilEmpresa", data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async alteraPerfilEmpresa({ commit }, payload) {
    try {
      const { data } = await perfilEmpresaService.patch(payload);
      commit("replacePerfilEmpresa", data);
    } catch (error) {
      return error;
    }
  },
};
const getters = {
  perfisEmpresa: (state) => state.perfisEmpresa,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
