import http from "@/plugins/axios";
import store from "@/store";

const VERSION = "/v1";

export default {
  getEmail(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/email", { params });
  },

  atualizarEmail(payload) {
    let id = payload.id;
    delete payload.id;
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(
      empresa.url_instancia + VERSION + "/email/" + id,
      payload
    );
  },
  atualizarSms(payload) {
    let id = payload.id;
    delete payload.id;
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(
      empresa.url_instancia + VERSION + "/sms/" + id,
      payload
    );
  },
  atualizarWhatsapp(payload) {
    let id = payload.id;
    delete payload.id;
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(
      empresa.url_instancia + VERSION + "/whatsapp/" + id,
      payload
    );
  },
  atualizarLigacao(payload) {
    let id = payload.id;
    delete payload.id;
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(
      empresa.url_instancia + VERSION + "/ligacao/" + id,
      payload
    );
  },

  getSms(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/sms", { params });
  },

  getLigacao(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/ligacao", {
      params,
    });
  },

  getWhatsapp(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/whatsapp", {
      params,
    });
  },
  novoEmail(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(empresa.url_instancia + VERSION + "/email", payload);
  },
  novoSms(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(empresa.url_instancia + VERSION + "/sms", payload);
  },
  novoWhatsapp(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(
      empresa.url_instancia + VERSION + "/whatsapp",
      payload
    );
  },
  novaLigacao(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(empresa.url_instancia + VERSION + "/ligacao", payload);
  },
  getPush(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/push_token", {
      params,
    });
  },

  getEmailById(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/email/" + id);
  },
  getSmsById(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/sms/" + id);
  },
  getLigacaoById(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/ligacao/" + id);
  },
  getWhatsappById(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/whatsapp/" + id);
  },
};
