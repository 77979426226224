export default [
  {
    path: "/emails",
    name: "emails",
    component: () =>
      import(/* webpackChunkName: "templateEmail" */ "./pages/index.vue"),
    meta: {
      title: "Emails",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/preview",
    name: "preview",
    component: () =>
      import(/* webpackChunkName: "templateEmail" */ "./pages/preview.vue"),
    meta: {
      title: "Preview",
      laout: "BlankLayout",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
  {
    path: "/emails/:id",
    name: "emails",
    component: () =>
      import(/* webpackChunkName: "templateEmail" */ "./pages/crudEmails.vue"),
    meta: {
      title: "Emails",
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
];
