import Vue from "vue";
import store from "@/store";
import moment from "moment";

Vue.filter("documentoCliente", (documento) => {
  if (!documento) {
    return "";
  }

  let mascara = documento.length > 10 ? "###.###.###-##" : "##.###.###/####-##";

  return Vue.filter("VMask")(documento, mascara);
});
Vue.filter("dataLocal", (data, formato) => {
  return moment.utc(data).local().format(formato);
});

Vue.filter("eventoStatus", (type) => {
  let types = {
    PRODUCAO: "Produção",
    RASCUNHO: "Rascunho",
    ARQUIVADA: "Arquivada",
    ARQUIVADO: "Arquivado",
  };

  return types[type] || type;
});

Vue.filter("numero", function (value) {
  return value.toLocaleString("pt-br");
});

Vue.filter("truncateString", function (text, maxLength) {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
});

Vue.filter("parcelaStatus", (type) => {
  let types = {
    EXPIRADA: "Expirada",
    DESCARTADA: "Descartada",
    PAGA: "Paga",
    PENDENTE: "Pendente",
    DESCONHECIDA: "Desconhecida",
  };

  return types[type] || type;
});

Vue.filter("telefone", (telefone) => {
  if (!telefone) {
    return "";
  }

  let mascara = telefone.length <= 10 ? "(##) ####-####" : "(##) #####-####";

  return Vue.filter("VMask")(telefone, mascara);
});

Vue.filter("formatarCpf", (documentId) => {
  documentId =
    typeof documentId != "string" ? documentId.toString() : documentId;

  documentId = documentId.padStart(11, "0");
  documentId = documentId.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );
  return documentId;
});

Vue.filter("telefoneFilter", (telefone) => {
  return telefone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
});

Vue.filter("cepFilter", (cep) => {
  return cep.replace(/[^0-8]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2");
});

Vue.filter("categoriaContrato", (type) => {
  let types = {
    PADRÃO: "Padrão",
    DIGITAL: "Digital",
  };
  return types[type] || type;
});

Vue.filter("statusContrato", (type) => {
  let types = {
    ATIVO: "Ativo",
    FINALIZADO: "Finalizado",
    CANCELADO: "Cancelado",
  };
  return types[type] || type;
});

Vue.filter("riscoContrato", (type) => {
  let types = {
    ALTO: "Alto",
    MEDIO: "Médio",
    BAIXO: "Baixo",
    INDEFINIDO: "Indefinido",
  };
  return types[type] || type;
});

Vue.filter("tipoContrato", (type) => {
  let types = {
    COBRANCA: "Acordo de Cobrança",
    CONSORCIO: "Consórcio",
    VAREJO: "Varejo",
    SEGURO: "Seguro",
    FINANCIAMENTO: "Financiamento",
    GENERICO: "Contrato Genérico",
  };
  return types[type] || type;
});

Vue.filter("tipoEvento", (type) => {
  let types = {
    COMANDO: "Comando",
    EMAIL: "E-mail",
    PUSH: "Push",
    RCS: "RCS",
    SMS: "SMS",
    WHATSAPP: "WhatsApp",
    FAST_SMS: "Fast SMS",
    LIGACAO_CELULAR: "Ligação Celular",
    LIGACAO_FIXO: "Ligação Fixo",
  };

  return types[type] || type;
});

Vue.filter("tipoNotificacao", (type) => {
  let types = {
    REGUA_COMUNICACAO: "Régua de Comunicação",
    FLUXO_AUTOMATICO: "Fluxo Automático",
    QUITEJA: "QuiteJá",
    TESTE_EVENTO: "Teste Evento",
  };

  return types[type] || type;
});

Vue.filter("tipoFluxoAutomatico", (type) => {
  let types = {
    LIGAR_AVISANDO_CAIXA_CHEIA: "Ligar Avisando Caixa Cheia",
    LIGAR_INFORMANDO_EMAIL_NAO_ENTREGUE: "Ligar Informando E-mail Não Entregue",
    LIGAR_SOLICITANDO_EMAIL: "Ligar Solicitando E-mail",
    LIGAR_INFORMANDO_EMAIL_NAO_ENTREGUE_PRIMEIRA_LIGACAO:
      "Ligar Informando E-mail Não Entregue (Primeira Ligação)",
    LIGAR_INFORMANDO_EMAIL_NAO_ENTREGUE_SEGUNDA_LIGACAO:
      "Ligar Informando E-mail Não Entregue (Segunda Ligação)",
    LIGAR_SOLICITANDO_EMAIL_PRIMEIRA_LIGACAO:
      "Ligar Solicitando E-mail (Primeira Ligação)",
    LIGAR_SOLICITANDO_EMAIL_SEGUNDA_LIGACAO:
      "Ligar Solicitando E-mail (Segunda Ligação)",
    SOLICITAR_OPTIN_WHATSAPP_VIA_SMS: "Solicitar Opt-in WhatsApp via SMS",
  };

  return types[type] || type;
});

Vue.filter("tipoRegua", (type) => {
  let types = {
    IMPLANTACAO: "Implantação",
    TOLERANCIA_BOLETO: "Tolerância do Boleto",
    TOLERANCIA_DEBITO: "Tolerância do Débito",
    VENCIMENTO_BOLETO: "Vencimento do Boleto",
    VENCIMENTO_DEBITO: "Vencimento do Débito",
    ANIVERSARIO_DO_CLIENTE: "Aniversário do Cliente",
    BOLETO_SOLICITADO: "Boleto Solicitado",
    CONTRATO_ATIVADO: "Contrato Ativado",
    CONTRATO_FINALIZADO: "Contrato Finalizado",
    CONTRATO_CANCELADO: "Contrato Cancelado",
    PAGAMENTO_BOLETO_RECEBIDO: "Pagamento Compensado Boleto",
    PAGAMENTO_DEBITO_RECEBIDO: "Pagamento Compensado Débito",
    PARCELA_BOLETO_CRIADA: " Fechamento de Fatura Boleto",
    PARCELA_DEBITO_CRIADA: "Fechamento de Fatura Débito",
    TERMINO_CONTRATO: "Término do Contrato",
    INICIO_CONTRATO: "Início do Contrato",
    MOVIMENTACAO: "Movimentação",
  };

  return types[type] || type;
});

Vue.filter("tipoReguaEvento", (type) => {
  const types = {
    IMPLANTACAO: "Implantação",
    VENCIMENTO_BOLETO: "Vencimento",
    VENCIMENTO_DEBITO: "Vencimento",
    TOLERANCIA_BOLETO: "Tolerância",
    TOLERANCIA_DEBITO: "Tolerância",
    ANIVERSARIO_DO_CLIENTE: "Aniversário",
    BOLETO_SOLICITADO: "Solicitação",
    INICIO_CONTRATO: "Início",
    CONTRATO_FINALIZADO: "Finalização",
    CONTRATO_CANCELADO: "Cancelamento",
    PAGAMENTO_BOLETO_RECEBIDO: "Pagamento",
    PAGAMENTO_DEBITO_RECEBIDO: "Pagamento",
    PARCELA_BOLETO_CRIADA: "Criação da Parcela",
    PARCELA_DEBITO_CRIADA: "Criação da Parcela",
    TERMINO_CONTRATO: "Término",
    MOVIMENTACAO: "Movimentação"
  }


  return types[type] || type;
});

Vue.filter("tituloBotao", (type) => {
  let types = {
    INICIO_CONTRATO: "Início",
    TERMINO_CONTRATO: "Término",
    TOLERANCIA_BOLETO: "Boleto",
    TOLERANCIA_DEBITO: "Débito",
    VENCIMENTO_BOLETO: "Boleto",
    VENCIMENTO_DEBITO: "Débito",
    CONTRATO_FINALIZADO: "Finalizado",
    CONTRATO_CANCELADO: "Cancelado",
    PAGAMENTO_BOLETO_RECEBIDO: "Boleto",
    PAGAMENTO_DEBITO_RECEBIDO: "Débito",
    PARCELA_BOLETO_CRIADA: "Boleto",
    PARCELA_DEBITO_CRIADA: "Débito",
  };

  return types[type] || type;
});

Vue.filter("statusCanais", (type) => {
  let types = {
    ATIVO: "Ativo",
    INVALIDO: "Inválido",
    INEXISTENTE: "Inexistente",
    DESCADASTRADO: "Descadastrado",
  };
  return types[type] || type;
});

Vue.filter("tipoTelefone", (type) => {
  let types = {
    CELULAR: "Celular",
    FIXO: "Fixo",
  };
  return types[type] || type;
});

Vue.filter("perfilCliente", (type) => {
  let perfis = {
    sem_perfil: "Sem perfil",
    total: "TOTAL",
  };

  let empresa = store.getters["empresa/empresaSelecionada"];
  let perfisRegua = empresa.perfis_regua || {};
  let perfisCliente = perfisRegua.perfis_cliente || [];

  perfisCliente.forEach((perfil) => {
    let nome = Object.keys(perfil)[0];
    perfis[nome] = perfil[nome];
  });

  return perfis[type] || type;
});

Vue.filter("perfilContrato", (type) => {
  let perfis = {
    sem_perfil: "Sem perfil",
    total: "TOTAL",
  };

  let empresa = store.getters["empresa/empresaSelecionada"];
  let perfisRegua = empresa.perfis_regua || {};
  let perfisContrato = perfisRegua.perfis_contrato || [];

  perfisContrato.forEach((perfil) => {
    let nome = Object.keys(perfil)[0];
    perfis[nome] = perfil[nome];
  });

  return perfis[type] || type;
});

Vue.filter("fomatadorReal", (value) => {
  if ((typeof value !== "number" && typeof value !== "string") || isNaN(value))
    return value;
  const valorInteiro = parseFloat(value);
  return valorInteiro.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
});

Vue.filter("formatadorRealDecimais", (value) => {
  if (!value) return "-";
  if ((typeof value !== "number" && typeof value !== "string") || isNaN(value))
    return value;
  const valorInteiro = parseFloat(value);
  if (value < 1) {
    return valorInteiro.toLocaleString("pt-br", {
      minimumFractionDigits: 4,
      style: "currency",
      currency: "BRL",
    });
  } else {
    return valorInteiro.toLocaleString("pt-br", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }
});

Vue.filter("formatadorRealDecimaisNCasas", (value) => {
  if (!value) return "--";
  if ((typeof value !== "number" && typeof value !== "string") || isNaN(value))
    return value;

  const valorDecimal = parseFloat(value);
  const decimais = String(valorDecimal).split(".");

  if (decimais[1]) {
    const arrDecimais = Array.from(decimais[1]);
    for (let i = arrDecimais.length + 1; i == 0; i--) {
      if (arrDecimais[i] === 0) arrDecimais.pop();
      else break;
    }
  }

  const sinal = decimais[0] < 0 ? "-" : "";
  const numAbsoluto = Math.abs(decimais[0]);
  let numDecimal = 0;

  if (!decimais[1]) {
    numDecimal = "00";
  } else {
    decimais[1].length < 2
      ? (numDecimal = decimais[1] + "0")
      : (numDecimal = decimais[1]);
  }

  return `${sinal} R$ ${numAbsoluto.toLocaleString("pt-br")},${numDecimal}`;
});

Vue.filter("tipoServico", (type) => {
  let types = {
    ENRIQUECIMENTO: "Enriquecimento",
    ENRIQUECIMENTO_INTERNO: "Enriquecimento Interno",
    ENRIQUECIMENTO_EXTERNO: "Enriquecimento Externo",
    NOTIFICACAO_EMAIL: "E-mail",
    NOTIFICACAO_EMAIL_CAPITALDOC: "E-mail (Fornecedor do parceiro)",
    NOTIFICACAO_EMAIL_SENDGRID: "E-mail (MeLembre)",
    NOTIFICACAO_LIGACAO_CELULAR: "Ligação Celular",
    COMANDO: "Comando",
    LIGACAO_RECEPTIVA: "Ligação Receptiva",
    NOTIFICACAO_FAST_SMS: "Fast SMS",
    NOTIFICACAO_FAST_SMS_PONTAL: "Fast SMS",
    NOTIFICACAO_LIGACAO_FIXO: "Ligação Fixo",
    NOTIFICACAO_RCS: "RCS",
    NOTIFICACAO_SMS: "SMS",
    NOTIFICACAO_SMS_MUTANT: "SMS (Fornecedor do parceiro)",
    NOTIFICACAO_SMS_PONTAL: "SMS (MeLembre)",
    NOTIFICACAO_SMS_INTERATIVO: "SMS Interativo",
    NOTIFICACAO_WHATSAPP: "WhatsApp Ativo",
    NOTIFICACAO_RECEPTIVO: "WhatsApp Receptivo",
    FRANQUIA: "Franquia",
    FRANQUIA_MINIMA: "Franquia Mínima",
    WHATSAPP_RECEPTIVO: "WhatsApp Receptivo",
    ACESSO_AUTOSSERVICO: "Acesso Autosserviço",
    ACESSO_NPS: "Acesso NPS",
    ACESSO_PESQUISA: "Acesso Pesquisa",
    ACESSO_BOLETO: "Acesso Boleto",
    ACESSO_AREA_LOGADA: "Acesso Área Logada",
    IMPORTACAO_CONTRATO: "Importação de Contrato",
    NOTIFICACAO_WHATSAPP_INFOBIP: "WhatsApp Ativo (MeLembre)",
    NOTIFICACAO_PUSH: "Push",
    NOTIFICACAO_PUSH_FIREBASE: "Push (MeLembre)",
  };

  return types[type] || type;
});

Vue.filter("tipoServicoDesformatar", (type) => {
  let types = {
    Enriquecimento: "ENRIQUECIMENTO",
    "Enriquecimento Interno": "ENRIQUECIMENTO_INTERNO",
    "Enriquecimento Externo": "ENRIQUECIMENTO_EXTERNO",
    "E-mail": "NOTIFICACAO_EMAIL",
    "E-mail (Fornecedor do parceiro)": "NOTIFICACAO_EMAIL_CAPITALDOC",
    "E-mail (MeLembre)": "NOTIFICACAO_EMAIL_SENDGRID",
    "Ligação Celular": "NOTIFICACAO_LIGACAO_CELULAR",
    Comando: "COMANDO",
    "Ligação Receptiva": "LIGACAO_RECEPTIVA",
    "Fast SMS": "NOTIFICACAO_FAST_SMS_PONTAL",
    "Ligação Fixo": "NOTIFICACAO_LIGACAO_FIXO",
    "Push (MeLembre)": "NOTIFICACAO_PUSH_FIREBASE",
    Push: "NOTIFICACAO_PUSH",
    RCS: "NOTIFICACAO_RCS",
    SMS: "NOTIFICACAO_SMS",
    "SMS (Fornecedor do parceiro)": "NOTIFICACAO_SMS_MUTANT",
    "SMS (MeLembre)": "NOTIFICACAO_SMS_PONTAL",
    "SMS Interativo": "NOTIFICACAO_SMS_INTERATIVO",
    "WhatsApp Ativo": "NOTIFICACAO_WHATSAPP",
    Franquia: "FRANQUIA",
    "WhatsApp Receptivo": "WHATSAPP_RECEPTIVO",
    "Acesso Autosserviço": "ACESSO_AUTOSSERVICO",
    "Acesso NPS": "ACESSO_NPS",
    "Acesso Pesquisa": "ACESSO_PESQUISA",
    "Acesso Boleto": "ACESSO_BOLETO",
    "Acesso Área Logada": "ACESSO_AREA_LOGADA",
    "Importação de Contrato": "IMPORTACAO_CONTRATO",
    "WhatsApp Ativo (MeLembre)": "NOTIFICACAO_WHATSAPP_INFOBIP",
  };

  return types[type] || type;
});

Vue.filter("statusFatura", (type) => {
  let types = {
    ABERTA: "aberta",
    APROVADA: "aprovada",
    PAGA: "paga",
    PAGO_PARCIAL: "pago parcial",
  };

  return types[type] || type;
});

Vue.filter("metodoPagamento", (type) => {
  let types = {
    BOLETO: "Boleto",
    DEBITO: "Débito",
  };

  return types[type] || type;
});

Vue.filter("canais", (type) => {
  let types = {
    EMAIL: "E-mail",
    SMS: "SMS",
    PUSH: "Push",
    FAST_SMS: "Fast SMS",
    LIGACAO: "Ligação Celular",
    LIGACAO_CELULAR: "Ligação Celular",
    LIGACAO_FIXO: "Ligação Fixo",
    COMANDO: "Comando",
    WHATSAPP: "WhatsApp",
  };

  return types[type] || type;
});

Vue.filter("statusNotificacao", (type) => {
  let types = {
    PENDENTE: "Pendente",
    ENVIADO: "Enviado",
    NAO_ENVIADO: "Não Enviado",
    ERRO: "Erro",
    ATENDIDA: "Atendida",
    NAO_ATENDIDA: "Não Atendida",
    RECEBIDO: "Recebido",
    ABRIU: "Abriu",
    CLICOU: "Clicou",
    VISUALIZADO: "Visualizado",
  };

  return types[type] || type;
});

Vue.filter("tipoNps", (type) => {
  let types = {
    CLIENTE: "Cliente",
    CONTRATO: "Contrato",
  };

  return types[type] || type;
});

Vue.filter("classificacoesIniciativa", (type) => {
  let types = {
    PENDENTE: "Pendente",
    MARKETING: "Marketing",
    AUTHENTICATION: "Authentication",
    UTILITY: "Utility",
  };

  return types[type] || type;
});

Vue.filter("cabecalhoIniciativa", (type) => {
  let types = {
    // 'TEXT': 'Texo',
    // 'VIDEO': 'Vídeo',
    DOCUMENT: "Documento",
    IMAGE: "Imagem",
    DOCUMENT_VAR: "Documento (Variável)",
    IMAGE_VAR: "Imagem (Variável)",
  };

  return types[type] || type;
});

Vue.filter("botoesIniciativa", (type) => {
  let types = {
    PHONE_NUMBER: "Número de telefone",
    QUICK_REPLY: "Resposta rápida",
  };

  return types[type] || type;
});

Vue.filter("statusIniciativa", (type) => {
  let types = {
    PENDENTE: "Pendente",
    APROVADA: "Aprovada",
    REPROVADA: "Reprovada",
  };

  return types[type] || type;
});

Vue.filter("corDoGrafico", () => {
  const coresDoMaterial = [
    "#673ab7",
    "#03a9f4",
    "#00bcd4",
    "#8bc34a",
    "#ff5722",
    "#607d8b",
  ];

  return coresDoMaterial[Math.floor(Math.random() * coresDoMaterial.length)];
});

Vue.filter("padStart", (value) => {
  if (value < 10) {
    return `0${value}`
  } return value
});
