import http from "@/plugins/axios";

export default {
  getAgenda(params) {
    return http.api.get("/v1/agenda", { params });
  },

  cadastrarAgenda(payload) {
    return http.api.post("/v1/agenda", payload);
  },

  editarAgenda(payload) {
    let id = payload.id;
    delete payload.id;
    return http.api.patch(`/v1/agenda/${id}`, payload);
  },
};
