export default [
  {
    path: "/iniciativas-whatsapp",
    name: "Iniciativas Whatsapp",
    component: () =>
      import(
        /* webpackChunkName: "console" */ "./pages/IniciativasWhatsapp.vue"
      ),
    meta: {
      title: "Iniciativas Whatsapp",
      precisaAutenticacao: true,
      precisaEmpresa: true
    },
  },
];
