<template>
  <v-app>
    <v-main class="main-blank pb-6">
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "BlankLayout",
};
</script>

<style lang="scss">
.main-blank {
  .v-main__wrap {
    div {
      padding: 20px;
      background: #f6f6f6;
    }
  }
  height: 100vh;
  overflow: auto;
}
</style>
