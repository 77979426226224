import http from "@/plugins/axios";
import store from "@/store";

export default {
  listar(params) {
    return http.api.get("/v1/variavel", {
      params,
      validateStatus: function (status) {
        return status < 400;
      },
    });
  },

  salvarVariavelMensagem(payload) {
    let id = payload.id;
    delete payload.id;
    return http.api.patch(`/v1/variavel/${id}`, payload);
  },

  adicionarVariavelMensagem(payload) {
    return http.api.post(`/v1/variavel`, payload);
  },

  excluirVariavelMensagem(id) {
    return http.api.delete(`/v1/variavel/${id}`);
  },

  /** Retorna a URL para pré-visualização de um asset. */
  criarUrlPreVisualizacaoAsset(urlAsset) {
    let parametros = { contrato_metadados: {}, parcela_metadados: {} };
    let variaveis = store.getters["variavelRegua/variaveis"];

    if (!urlAsset) {
      return null;
    }

    if (!variaveis) {
      return urlAsset + "?use_preview_params=true";
    }

    variaveis.forEach((variavel) => {
      parametros[variavel.nome] = `{{ ${variavel.nome} }}`;
    });

    return urlAsset + "?use_preview_params=true";
  },

  /** Retorna a mensagem com as variáveis substituídas pelos exemplos. */
  preVisualizarMensagem(mensagem) {
    let variaveis = store.getters["variavelRegua/variaveis"];
    let empresa = store.getters["empresa/empresaSelecionada"];

    if (!mensagem) {
      return mensagem;
    }

    if (!variaveis) {
      return mensagem;
    }

    let preVisualizacao = mensagem;

    variaveis.forEach((variavel) => {
      let exemplo = "";
      if (variavel.nome != "link_area_logada") {
        exemplo = variavel.exemplo;
      } else {
        if (variavel.exemplo[empresa.chave]) {
          exemplo = variavel.exemplo[empresa.chave];
        } else {
          exemplo = variavel.exemplo["padrao"];
        }
      }
      while (preVisualizacao.includes(`{{${variavel.nome}}}`)) {
        preVisualizacao = preVisualizacao.replace(
          `{{${variavel.nome}}}`,
          `<strong>${exemplo}</strong>`
        );
      }
    });

    return preVisualizacao;
  },
};
