export default [
  {
    path: "/info",
    name: "Info",
    component: () => import(/* webpackChunkName: "console" */ "./pages/Info"),
    meta: {
      title: "Informações",
      precisaAutenticacao: true,
      precisaEmpresa: true
    },
  },
];
