import clienteService from "@/services/cliente";

const state = {
  consultaClientes: null,
  cliente: null,

  carregandoCliente: false,
  erroCarregarCliente: false,
};

const mutations = {
  setCliente(state, cliente) {
    state.cliente = cliente;
  },
  setConsultaClientes(state, payload) {
    state.consultaClientes = payload;
  },
  setCarregandoCliente(state, payload) {
    state.carregandoCliente = payload;
  },
  setErroCarregarCliente(state, payload) {
    state.erroCarregarCliente = payload;
  },
};

const actions = {
  async atualizar({}, cliente) {
    const payload = removeClientFields(cliente);
    const { data, error } = await clienteService.atualizar(cliente.id, payload);
    return data || error;
  },
  async getClientes({ commit }, params) {
    params["incluir"] = "contratos";
    await clienteService.consultaClientes(params).then((retorno) => {
      commit("setConsultaClientes", retorno.data);
    });
  },
  limparClientes({ commit }) {
    commit("setConsultaClientes", null);
  },
  async getClienteById({ commit }, id_cliente) {
    commit("setCarregandoCliente", true);
    let payload = {
      incluir: "contratos",
    };
    try {
      const { data } = await clienteService.getCliente(payload, id_cliente);
      commit("setCarregandoCliente", false);
      commit("setErroCarregarCliente", false);
      return data;
    } catch {
      commit("setCarregandoCliente", false);
      commit("setErroCarregarCliente", true);
    }
  },
};

const getters = {
  cliente: (state) => state.cliente,
  consultaClientes: (state) => state.consultaClientes,

  carregandoCliente: (state) => state.carregandoCliente,
  erroCarregarCliente: (state) => state.erroCarregarCliente,
};

function removeClientFields(client) {
  const fieldsToRemove = ["atualizado_em", "criado_em", "data_nascimento"];
  for (const [key] of Object.entries(client)) {
    if (fieldsToRemove.includes(key)) delete client[key];
  }
  return client;
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
