const state = {
  snackBar: {
    text: null,
    app: true,
    iconColor: "red",
    active: false,
    timeout: 4000,
    color: null,
    closable: false,
    attrs: {},
  },
};
const mutations = {
  setEmpresas: (state, payload) => {
    state.empresas = payload;
  },
  setActive: (state) => {
    state.snackBar.active = false;
  },
};
const actions = {
  showSnackBar({ state }, payload) {
    state.snackBar.active = true;
    Object.assign(state.snackBar, { ...payload });
  },
};
const getters = {
  snackbar: (state) => state.snackBar,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
