import http from "@/plugins/axios";
import store from "@/store";

const VERSION = "/v1";

export default {
  getEmpresaPorId(idEmpresa, params) {
    return http.api.get(`${VERSION}/empresa/${idEmpresa.id}`, { params });
  },
  editarEmpresa(empresaId, params) {
    return http.api.patch(`${VERSION}/empresa/${empresaId}`, params);
  },
  editarEmailCobranca(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.patch(
      `/v1/empresa/${empresa.id}/emails_faturamento`,
      params
    );
  },
  getEmpresaBrokers(idEmpresa) {
    return http.api.get(`${VERSION}/empresa/${idEmpresa}/broker`);
  }
};
